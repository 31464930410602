import checkoutTemplate from './templates/checkout_summary.hbs'
import paymentMethodTemplate from './templates/payment_methods.hbs'
import collectPaymentTemplate from './templates/collect_payment.hbs'
import {setUIComponent, clearUI, compileTemplate, trans, laravelErrorBag} from './utils'
import parsePhoneNumber from 'libphonenumber-js'

import mpesaSvg from '../assets/mpesa.svg'
import cardSvg from '../assets/card.svg'
import succeededSvg from '../assets/success.svg'
import failedSvg from '../assets/failed.svg'

export const showCartSummary = async (event, sdk, {total, items, reference}) => {
    return new Promise((resolve) => {
        function setupUI() {
            const template = compileTemplate(checkoutTemplate, sdk.lang, {
                total,
                items,
                reference,
                action: 'confirmAndPay'
            })
            const root = setUIComponent(template)
            const container = root.getElementById('ui-root')
            const errorContainer = root.getElementById('display-error')
            container.classList.add("show")
            const close = root.getElementById('ui-close')
            close.addEventListener('click', () => {
                clearUI()
            })
            const nextAction = () => {
                clearUI()
                resolve(showPaymentMethods(event, sdk, total))
            }
            const next = root.getElementById('ui-next')
            const progress = root.getElementById("payment-progress")
            const action = root.getElementById("actionText")
            next.addEventListener('click', nextAction)
            progress.classList.remove("d-none")
            next.disable = true
            sdk.startSession({amount: total, reference}).catch(e => {
                progress.classList.add("d-none")
                next.disable = false
                const errorBag = laravelErrorBag(e.response?.data ?? {})
                const message = e.response?.data?.message ?? (
                    errorBag ? errorBag.join("<br/>") : trans(sdk.lang, 'fatalError')
                )
                errorContainer.classList.remove("d-none")
                errorContainer.innerText = message
                action.innerText = trans(sdk.lang, 'close')
                next.addEventListener('click', () => {
                    clearUI()
                    resolve()
                })
                sdk.log('debug', `${trans(sdk.lang, "apiResponse")} '${e.config.url}'`, e.response)
                return e
            }).then(() => {
                progress.classList.add("d-none")
                next.disable = false
            })
        }

        setupUI();
    })
}

export const showPaymentMethods = async (event, sdk, total) => {
    return new Promise((resolve) => {
        function setupUI() {
            const channels = [
                {
                    logo: mpesaSvg,
                    name: "mpesa"
                }, {
                    logo: cardSvg,
                    name: "card"
                }
            ]
            const template = compileTemplate(paymentMethodTemplate, sdk.lang, {total, channels})
            const root = setUIComponent(template)
            const container = root.getElementById('ui-root')
            container.classList.add("show")
            const error = root.getElementById("input-error")
            const close = root.getElementById('ui-close')
            close.addEventListener('click', () => {
                clearUI()
            })
            const next = root.getElementById('ui-next')
            const paymentMethods = root.querySelectorAll(".form-selectgroup-input")
            for (let input of paymentMethods) {
                input.addEventListener('click', () => {
                    error.classList.remove("d-block")
                })
            }

            next.addEventListener('click', () => {
                const paymentMethods = root.querySelectorAll("input[name=payment-form]:checked")
                if (paymentMethods.length > 0) {
                    error.classList.remove("d-block")
                    const channel = paymentMethods[0].value;
                    resolve(collectPaymentViaMethod(event, sdk, channel, total))
                } else {
                    error.classList.add("d-block")
                }
            })
        }

        setupUI();
    })
}

export const collectPaymentViaMethod = async (event, sdk, channel, total) => {
    return new Promise((resolve) => {
        function setupUI() {
            const prefix = '258'
            const template = compileTemplate(collectPaymentTemplate, sdk.lang, {channel, total, prefix})
            const root = setUIComponent(template)
            const container = root.getElementById('ui-root')
            container.classList.add("show")

            const error = root.getElementById("input-error")
            const close = root.getElementById('ui-close')
            close.addEventListener('click', () => {
                clearUI()
            })
            const next = root.getElementById('ui-next')
            const input = root.getElementById("phone-input")
            const action = root.getElementById("actionText")
            const statusMessage = root.getElementById("transaction-status")
            const statusImage = root.getElementById("status-image")
            const statusContainer = root.getElementById("status-indicator")
            const detailsContainer = root.getElementById("transaction-details")
            const errorContainer = root.getElementById('display-error')

            input.addEventListener('keyup', (e) => {
                const phoneNumber = parsePhoneNumber(e.target.value, 'MZ')
                if (phoneNumber && phoneNumber.isValid()) {
                    error.classList.remove("d-block")
                } else {
                    error.classList.add("d-block")
                }
            })
            const attemptPayment = () => {
                const phoneNumber = parsePhoneNumber(input.value, 'MZ')
                if (phoneNumber && phoneNumber.isValid()) {
                    const progress = root.getElementById("payment-progress")
                    error.classList.remove("d-block")
                    progress.classList.remove("d-none")
                    sdk.attemptPayment({amount: total, channel, phoneNumber: phoneNumber.number}).then(() => {
                        statusMessage.classList.remove("border-danger")
                        statusMessage.classList.add("border-success")
                        statusMessage.innerText = trans(sdk.lang, 'transactionSucceeded')
                        detailsContainer.classList.add("d-none")
                        statusContainer.classList.remove("d-none")
                        statusImage.src = succeededSvg
                        statusImage.classList.remove("bg-danger")
                        statusImage.classList.add("bg-success")
                        progress.classList.add("d-none")
                        errorContainer.classList.add("d-none")
                        action.innerText = trans(sdk.lang, 'complete')
                        next.removeEventListener('click', attemptPayment)
                        next.addEventListener('click', completePayment)
                    }).catch(e => {
                        const errorBag = laravelErrorBag(e.response?.data ?? {})
                        const message = e.response?.data?.message ?? (
                            errorBag ? errorBag.join("<br/>") : trans(sdk.lang, 'fatalError')
                        )
                        errorContainer.classList.remove("d-none")
                        errorContainer.innerHTML = message

                        statusMessage.classList.remove("border-success")
                        statusMessage.classList.add("border-danger")
                        statusMessage.innerText = trans(sdk.lang, 'transactionFailed')
                        statusImage.src = failedSvg
                        statusImage.classList.add("bg-danger")
                        progress.classList.add("d-none")
                        statusContainer.classList.remove("d-none")
                        detailsContainer.classList.add("d-none")
                        action.innerText = trans(sdk.lang, 'retry')
                    })
                } else {
                    error.classList.add("d-block")
                }
            }

            const completePayment = () => {
                clearUI()
                resolve()
            }
            next.addEventListener('click', attemptPayment)
        }

        setupUI();
    })
}
