import {request} from './api';
import {showCartSummary, showError} from "./ui";
import {trans} from "./ui/utils";

const checkout = async function (event, reference, items) {
    try {
        this.log("debug", "Starting checkout", {items})
        const amount = items.reduce((acc, current) => acc + current.amount, 0)
        if (amount < 1 || Number.isNaN(amount)) {
            return showError(event, this, 'invalidTransactionAmount')
        } else {
            return showCartSummary(event, this, {items, total: amount, reference})
        }
    } catch (e) {
        this.log("error", e.message, e.stack.toString())
    }
}

const setLogger = function (logger) {
    return Object.assign(this, {log: logger.bind(this)})
}
const startSession = async function (payload) {
    const res = await this.api.post('/session', {...payload})
    this.log('debug', `${trans(this.lang, "apiResponse")} '/session'`, res)
    this.sessionId = res.data.session
    return res
}
const attemptPayment = async function (payload) {
    const res = await this.api.post('/attempt', {...payload, session: this.sessionId, language: this.lang})
    this.log('debug', `${trans(this.lang, "apiResponse")} '/payment'`, res)
    return res
}

const reset = (payload) => {
    return initializeWalletSDK(...payload)
}

const defaultLogger = function (level, message, payload) {
    this.logs.push({level: level.toLowerCase(), message, payload})
    if (this.logs.length > 10) {
        this.logs.splice(0, 1)
    }
}


export let initializeWalletSDK = function (apiKey, sandbox = true, endPoint = null, lang = 'pt') {
    endPoint = endPoint ? endPoint : request({sandbox}).domain()
    const state = {
        lang,
        logs: [],
        apiKey: "",
        api: request({domain: endPoint, sandbox, headers: {authorization: `Bearer ${apiKey}`, "accept-language": lang}})
    }
    const sdk = Object.assign(state, {
        apiKey,
        reset: reset.bind(state),
        setLogger: setLogger.bind(state),
        checkout: checkout.bind(state),
        attemptPayment: attemptPayment.bind(state),
        initializeWalletSDK,
        startSession: startSession.bind(state),
    })
    sdk.setLogger(defaultLogger)
    sdk.log("debug", trans(sdk.lang, "initializingSdk"), {apiKey, sandbox, endPoint, lang})
    return sdk;
}

global.initializeWalletSDK = initializeWalletSDK
export default initializeWalletSDK
