import axios from 'axios';

export function validateCheckoutDetails(details, exclude = []) {
    const errors = []
    const validator = [
        {
            name: 'amount',
            message: 'Checkout amount is a required field'
        },
    ]

    for (const field of validator) {
        if (exclude.indexOf(field.name) === -1 && !details[field.name]) {
            errors.push(field.message)
        }
    }

    return errors
}

export function request(options) {
    const actions = {
        endpoint: (url) => {
            if (url.indexOf('http') === 0) {
                return url
            } else {
                let prefix = '/payments'
                if (url.slice(0, 1) !== '/') {
                    prefix += '/'
                }
                return `${actions.domain()}/api${prefix}${url}`
            }
        },
        domain: () => {
            return options.domain ? options.domain : `https://ewallet${options.sandbox ? '.dev' : ''}.nhutchi.co.mz`
        },
        post: async (url, data) => {
            return await axios.post(actions.endpoint(url), data, {headers: options.headers})
        },
        get: async (url, params) => {
            return await axios.get(actions.endpoint(url), {params, headers: options.headers})
        },
        health: async () => {
            return await actions.get('/health')
        }
    }
    return actions
}


