// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js!../../../../node_modules/@tabler/core/dist/css/tabler.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root,:host{font-size:16px;height:100%;--tblr-primary: #80ad33;--tblr-primary-rgb: 98, 102, 184;--tblr-primary-fg: var(--tblr-light)}.bg-white{background:#f1f5f9 !important}`, "",{"version":3,"sources":["webpack://./src/ui/templates/css/styles.scss"],"names":[],"mappings":"AAEA,YACE,cAAA,CACA,WAAA,CACA,uBAAA,CACA,gCAAA,CACA,oCAAA,CAGF,UACE,6BAAA","sourcesContent":["@import url('@tabler/core');\n\n:root, :host {\n  font-size: 16px;\n  height: 100%;\n  --tblr-primary: #80ad33;\n  --tblr-primary-rgb: 98, 102, 184;\n  --tblr-primary-fg: var(--tblr-light);\n}\n\n.bg-white {\n  background: #f1f5f9 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
