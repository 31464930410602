import css from '../templates/css/styles.scss'
import translation from './translate.json'
import Handlebars from 'handlebars'

export function trans(lang, key) {
    if (translation[lang][key]) {
        return translation[lang][key]
    } else {
        if (translation[translation["default"]][key]) {
            logMessage("Translation not found in selected language", {lang, key})
            return translation[translation["default"]][key]
        } else {
            throw new Error(`Invalid translation [${lang}]:[${key}] `)
        }
    }
}

export function compileTemplate(file, lang, payload) {
    Handlebars.registerHelper("trans", (key) => {
        try {
            return trans(lang, key)
        } catch (e) {
            return e.message
        }
    })

    Handlebars.registerHelper("increment", (value) => {
        return value + 1
    })

    Handlebars.registerHelper("uppercase", (value) => {
        return value.toUpperCase()
    })

    Handlebars.registerHelper("currency", (value) => {
        try {
            return `${new Intl.NumberFormat("en", {
                style: 'currency',
                currency: 'mzn'
            }).format(value)}`
        } catch (e) {
            return e.message
        }
    })
    return Handlebars.compile(file)(payload);
}

export function throttle(func, delay) {
    let prev = 0
    return (...args) => {
        const now = new Date().getTime()
        if (now - prev > delay) {
            prev = now
            return func(...args)
        }
    }
}

export function setUIComponent(component, documentRoot) {
    clearUI()
    const el = document.createElement('div')
    el.setAttribute('id', 'sdk-ui-root')
    if (documentRoot) {
        documentRoot.innerHTML = el.outerHTML
    } else {
        document.getElementsByTagName('body')[0].insertAdjacentHTML('beforeend', el.outerHTML)
    }
    const root = document.getElementById('sdk-ui-root')
    const shadow = root.attachShadow({mode: 'open'})
    shadow.innerHTML = `<style>${css.toString().replace(":root {", ":root, :host {")}</style><div id="sdk-ui-body">${component}</div>`;
    return shadow
}

export function clearUI(documentRoot = document) {
    if (documentRoot.getElementById('sdk-ui-root')) {
        const root = documentRoot.getElementById('sdk-ui-root')
        root.parentNode.removeChild(root)
    }
}

export function logMessage(ctx, text) {
    // BRISO.logger('info', text, photo)
}

export function laravelErrorBag(errorBag) {
    const errorMessages = [];
    for (const field in errorBag) {
        if (errorBag.hasOwnProperty(field)) {
            errorBag[field].forEach(error => {
                errorMessages.push(`${error}`);
            });
        }
    }
    return errorMessages
}
