import {clearUI, compileTemplate, setUIComponent} from "./utils";
import errorTemplate from "./templates/message.hbs";

export const showError = async (event, sdk, message) => {
    return new Promise((resolve) => {
        function setupUI() {
            const left = event.clientX
            const top = event.clientY
            const template = compileTemplate(errorTemplate, sdk.lang, {
                title: "error",
                message,
                left: `${left}px`,
                top: `${top}px`,
                translateY: "-50%",
                translateX: "20px"
            })
            const root = setUIComponent(template)
            const close = root.getElementById('ui-close')
            close.addEventListener('click', () => {
                clearUI()
                resolve()
            })
        }

        setupUI();
    })
}